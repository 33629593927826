import React, { useState } from 'react'
import {Sling as Hamburger} from 'hamburger-react'
import { Link } from 'gatsby'

import { RiVimeoLine, RiInstagramLine, RiLinkedinLine } from 'react-icons/ri'

import Menu from './Menu'
import SEO from './SEO/'
import DarkModeToggler from './DarkModeToggler'

export default function HeroWrapper({ children }) {
  
  const [isOpen, setOpen] = useState(false)

  return (
    <main className="font-brand font-thin text-yellowstone h-screen theme-dark bg-primary text-text-main">
      <SEO />
      <title>Nicole Medvecka</title>
      <div className="flex flex-col h-screen text-center">

        <div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-10 sm:px-6 lg:px-8 z-20">
            <h1 className=" text-center text-lg tracking-widest text-yellowstone sm:text-lg lg:text-xl">
              <Link to="/">NICOLE MEDVECKA . CINEMATOGRAPHER</Link>
            </h1>
          </div>
          
          <div className="flex items-center fixed top-6 right-4 xl:right-4 z-20">
            <DarkModeToggler />
            <Hamburger toggled={isOpen} toggle={setOpen} color="#716f6a" distance="lg" size={32} label="Show menu" />
          </div>
        </div>

        <div className="flex-grow">
          {children}
        </div>
      
        <div className="mx-auto px-0 my-10 text-yellowstone flex z-20">
          <span className="text-center mx-auto flex flex-row items-center space-x-4">
            <a href="https://vimeo.com/nicole8mn" target="_blank" rel="noreferrer"><RiVimeoLine size="1.5em" /></a>
            <a href="https://instagram.com/nicole_8mn" target="_blank" rel="noreferrer"><RiInstagramLine size="1.5em" /></a>
            <a href="https://www.linkedin.com/in/nicole-medvecka-519a26258" target="_blank" rel="noreferrer"><RiLinkedinLine size="1.5em" /></a>
          </span>
        </div>
        {isOpen && <Menu />}
      </div>
    </main>
  )
}