import React from 'react'

import HeroWrapper from '../components/HeroWrapper'
import Hero from '../components/Hero'

const IndexPage = () => {

  return (
    <HeroWrapper>
      <Hero />
    </HeroWrapper>
  )
}

export default IndexPage
