import React from 'react'
import ReactPlayer from 'react-player'
import { StaticImage } from 'gatsby-plugin-image'

import HeroVideo from '../images/hero_video.mp4'

export default function Hero() {
  return (
    <div className="absolute inset-0 mt-24">
      <ReactPlayer
        url={HeroVideo}
        playing
        loop
        muted
        playsinline
        width="100%"
        height="90%"
      />
    </div>
  )
}